import React, { useState } from 'react';
import Modal from 'react-modal';
// import ReactPlayer from 'react-player';
import { AiOutlineCloseCircle as IconClose } from 'react-icons/ai';

// import ModalError from './ModalError';
import DownloadLink from './DownloadLink';

import Icon from './shapes/icon-videos.svg';

/* eslint-disable jsx-a11y/media-has-caption */
export default function VideoLink({ video, children, title, className, ...props }) {
  const toClean = video.replace('.mp4', '').replace('/', '-');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);
  // const onError = () => {
  //   setModalIsOpen(false);
  //   setModalErrorIsOpen(true);
  // };
  return <div className="link with-download-link">
    <button className={`link-video link-video--${toClean}${className ? ` ${className}` : ''}`} {...props} onClick={() => setModalIsOpen(true)}>
      <Icon />
      <span className="name">{ title }</span>
      { children }
    </button>
    <DownloadLink url={`/mp4/${video.replace('.mp4', '')}_HD.mp4`} slug={toClean} ext="mp4" title={`Télécharger la vidéo ${title}`} />
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setModalIsOpen(false)}
      contentLabel={ title }
      className="modal-standard modal-video"
      overlayClassName="modal-overlay"
      ariaHideApp={false}
    >
      <div className="inner">
        {modalIsOpen ? <div>
          <video
            controls={true}
            preload="auto"
            autoplay={true}
          >
            <source src={`/mp4/${video.replace('.mp4', '')}_SD.mp4`} type="video/mp4" media="(max-width: 1280px)" />
            <source src={`/mp4/${video.replace('.mp4', '')}_HD.mp4`} type="video/mp4" media="(min-width: 1281px)" />
            <source src={`/mp4/${video.replace('.mp4', '')}_SD.webm`} type="video/webm" />
          </video>
        </div> : null}
      </div>
      <button className="close" onClick={() => setModalIsOpen(false)} title="Fermer"><IconClose /><span className="sr-only">Fermer</span></button>
    </Modal>
    {/* <ModalError isOpen={modalErrorIsOpen} setIsOpen={setModalErrorIsOpen} contentLabel={ title } /> */}
  </div>
}
/* eslint-enable jsx-a11y/media-has-caption */
