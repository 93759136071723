import React, { Fragment, useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import PDFLink from '../components/PDFLink';
import VideoLink from '../components/VideoLink';

import GLOBAL_MEDIA_QUERIES from '../constants/media-queries';

export default function Ressources({ data }) {
  const [openedDescription, openDescription] = useState(null);
  const handleOpenDescription = slug => openDescription(slug === openedDescription ? null : slug);
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.tabletImage.childImageSharp.fluid,
      media: GLOBAL_MEDIA_QUERIES.tablet,
    },
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: GLOBAL_MEDIA_QUERIES.desktop,
    },
  ];
  return <Fragment>
    <div className="container">
      <h1>Les ressources</h1>
      <div className="grid">
        <div className="column-half">
          <h2>Les ressources à imprimer</h2>
          <div className="btn-group">
            <PDFLink openedDescription={openedDescription} openDescription={handleOpenDescription} pdf="boite_a_mots" title="La Boîte à mots" />
            <PDFLink openedDescription={openedDescription} openDescription={handleOpenDescription} pdf="cahier_alpage" title="Le Carnet de l'alpage" />
            <PDFLink openedDescription={openedDescription} openDescription={handleOpenDescription} pdf="dessins_pas_a_pas" title="Dessin pas-à-pas" />
            <PDFLink openedDescription={openedDescription} openDescription={handleOpenDescription} pdf="marottes_decors-hd" title="Le décor des marottes" />
            <PDFLink openedDescription={openedDescription} openDescription={handleOpenDescription} pdf="marottes_personnages-hd" title="Les marottes" />
            <PDFLink openedDescription={openedDescription} openDescription={handleOpenDescription} pdf="poster_hd_recto" title="Le poster des 4 saisons" />
            <PDFLink openedDescription={openedDescription} openDescription={handleOpenDescription} pdf="les_masques" title="Les masques" />
          </div>
        </div>
        <div className="column-half">
          <h2>Les activités en vidéo</h2>
          <div className="btn-group">
            <VideoLink video="YAOURT" title="Transformation du lait : yaourt et beurre" />
            <VideoLink video="PINCEAUX" title="Les outils naturels pour tracer" />
            <VideoLink video="GRAMINEE" title="Semis des graminées en godet puis plantation des graminées" />
          </div>
        </div>
      </div>
    </div>
    <div className="image-bg"><Img fluid={sources} /></div>
  </Fragment>
}

export const query = graphql`
query {
  mobileImage: file(relativePath: { eq: "ressources-bg.png" }) {
    childImageSharp {
      fluid(maxWidth: 354, quality: 100) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
  }
  tabletImage: file(relativePath: { eq: "ressources-bg.png" }) {
    childImageSharp {
      fluid(maxWidth: 668, quality: 100) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
  }
  desktopImage: file(relativePath: { eq: "ressources-bg.png" }) {
    childImageSharp {
      fluid(maxWidth: 788, quality: 100) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
  }
}
`;
